import React from 'react';
import IntroPage from "./IntroPage";

function Home() {
    return (
        <div>
            <IntroPage />
        </div>
    );
}

export default Home;