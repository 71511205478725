import React from 'react';

function BrandName() {
    return (
        <>
            {/*<span className={"Headland font-bold text-[20px]"}>P</span>ark's<span className={"Headland font-bold text-[20px]"}>G</span>olf*/}
            <span className={"Headland font-bold text-[20px]"}>J.S. </span>
            park golf
        </>
    );
}

export default BrandName;